// src/Components/UI/Organisms/tPComponent.tsx
import React, { useState,  forwardRef, useEffect } from "react";
import {  Table, Button, ToggleButton, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import VehicleSelection from "../Molecules/vehicleSelecton";
import { Option } from "pasenture-components/dist/types&interface";

import "./TPComponent.css";
import Data from "../../../tPData.json";
import { GST_RATES } from "../../../config";
import ODComponent from "./oDComponent";

interface TPComponentProps {
  tp: number;
  onTpChange: (delta: number) => void;
  onOdChange: (delta: number) => void;
  onGSTChange: (rate: number) => void;
  
}




interface Rate {
  basic: number;
  new: number;
  tppd: number;
  cng?: number;
  component_tp?: number;
}

interface TPData {
  min: number;
  max: number;
  rate: Rate;
  gst?: number;
}

interface ToggleState {
  [key: string]: boolean;
}
 

export interface VehicleCategory {
  id: string;
  name: string;
  tp?: {
    [key: string]: any; // This can include cc, ev, and other fields dynamically
  };
  od?: {
    [key: string]: any; // This can include cc, ev, and other fields dynamically
  };
}

const TPComponent=   forwardRef<any, TPComponentProps>(({ tp, onTpChange, onOdChange, onGSTChange }, ref) => {
 
  const [gstRate, setGstRate] = useState<number>(GST_RATES.DEFAULT);
  const [vehicleData, setVehicleData] = useState<any>(null);
  const [cpaValue, setCpaValue] = useState<number>(0);
  const [gstAmount, setGstAmount] = useState<number>(0);
  const [scInputValue, setScInputValue] = useState<number>(0);
  const [rateData, setRateData] = useState<TPData | null>(null);
  const [inputValue, setInputValue] = useState<number>(0);

  const [odGst, setOdGst] = useState<number>(0);

//  these are states for showing summary of TP component
 const [basicTPPrem, setBasicTPPrem] = useState<number>(0);
const [scTPinPCV, setScTPinPCV] = useState<number>(0);
const [ll, setLl] = useState<number>(0);
const [cng, setCng] = useState<number>(0);
const [cpa, setCpa] = useState<number>(0);
const [tppd, setTppd] = useState<number>(0);
const [finalTpPrem, setFinalTpPrem] =useState<number>(0);


  const vehicleOptions: Option[] = Data.map((vehicle: any) => ({
    value: vehicle.id,
    label: vehicle.name,
  }));

  const [inputValues, setInputValues] = useState([
    { id: "cc", label: "CC", value: "", disabled: true },
    { id: "ev", label: "WATT(KW)", value: "", disabled: true },
    { id: "sc", label: "SC", value: "", disabled: true },
    { id: "gvw", label: "GVW", value: "", disabled: true },
    { id: "hp", label: "HP", value: "", disabled: true },
  ]);

  const [selectedToggle, setSelectedToggle] = useState<ToggleState>({
     CNG:  false,
    ev: false,
    TPPD: false,
    NEW: false,
  });

   const [selectedVehicle, setSelectedVehicle] = useState<VehicleCategory | null>(null);
  
  

  const updateInputValues = (vehicle: VehicleCategory | null) => {
    if (vehicle?.tp) {
      const newInputValues = inputValues.map((input) => {
        const isEnabled = vehicle.tp?.hasOwnProperty(input.id) ?? false;

        if (input.id === "ev") {
          return { ...input,  disabled: !(isEnabled && selectedToggle.ev) };
        }
        if (input.id === "cc") {
          if (selectedToggle.ev) {
            return { ...input, value: "", disabled: true };
          }
          return { ...input, disabled: !isEnabled };
        }
  

        return { ...input,  disabled: !isEnabled };
      });
      setInputValues(newInputValues);
    } else {
      setInputValues((prev) =>
        prev.map((input) => ({ ...input, disabled: true, value: "" }))
      );

    }
  };
  
  

  const calculateRateData = () => {
    if (!selectedVehicle) return;
  
    const inputType = inputValues.find((v) => !v.disabled)?.id;
  
    if (!inputType) return;  // Early return if inputType is undefined
  
    const inputValueStr = inputValues.find((v) => v.id === inputType)?.value;
    const inputValueNum = parseFloat(inputValueStr ?? "0");
  
    setInputValue(inputValueNum);
  
    const rateDataObj = selectedVehicle.tp?.[inputType]?.find(
      (item: TPData) => inputValueNum >= item.min && (item.max === null || inputValueNum <= item.max)
    );
      setGstAmount(0);
    setRateData(rateDataObj || null);
  };


  useEffect(() => {
    calculateRateData();
  }, [selectedVehicle, inputValues, selectedToggle]);

  
  const handleGstRateChange = (rate: number) => {
    setGstRate(rate);
  };
  const handleVehicleDataChange = (data: any) => {
    setVehicleData(data);
  };
  const calculateTP = () => {
    if (!selectedVehicle || !rateData || !rateData.rate || inputValue <= 0) {
      console.log("Invalid data or no vehicle selected. Calculation aborted.");
      resetValues();
      return;
    }
 
    let tpValue = selectedToggle.NEW && rateData.rate.new ? rateData.rate.new : rateData.rate.basic;

    let basicTP = tpValue;
    setBasicTPPrem(basicTP);
    const vehicleGstRate = selectedVehicle.tp?.gst ?? GST_RATES.DEFAULT;
    const basicTPGstAmount = (basicTP * vehicleGstRate) / 100;
      
  console.log("basicgst", basicTPGstAmount);
  
    setCng(selectedToggle.CNG ? rateData.rate.cng ?? 0 : 0);
    setCpa(cpaValue);
    setTppd(selectedToggle.TPPD ? rateData.rate.tppd : 0);
    

    if (selectedToggle.TPPD) {
      tpValue -= rateData.rate.tppd;
    }

    if (selectedToggle.CNG && rateData.rate.cng) {
      tpValue += rateData.rate.cng;
    }

   
    
     if(rateData.rate.component_tp){
      const scTPValue = rateData.rate.component_tp * (scInputValue || 0);
      console.log(scTPValue);
      tpValue += scTPValue;
      setScTPinPCV(scTPValue);
     }else{
      setScTPinPCV(0);
     }

    tpValue += cpaValue + ll;

  const otherAmount = tpValue - basicTP;
  //console.log("totaltp", otherAmount  )
  const otherGstAmount = (otherAmount * GST_RATES.DEFAULT) / 100;
  
  const totalGstAmount = basicTPGstAmount + otherGstAmount;
  // console.log("Basic TP GST Amount:", basicTPGstAmount);
  //   console.log("Other GST Amount:", otherGstAmount);
  //   console.log("Total GST Amount:", totalGstAmount);

    setGstAmount(totalGstAmount);
    onGSTChange(totalGstAmount + odGst); 
    // console.log("onGst", gstAmount);

    
  // console.log("tpDelta", tpDelta);
  // console.log("tp", tp);
  // console.log("tpvalue", tpValue);
    setFinalTpPrem(tpValue);
    onTpChange(tpValue);
  };

  const resetValues = () => {
    setBasicTPPrem(0);
    setScTPinPCV(0);
    setCpaValue(0);
     setLl(0);
    setCng(0);
    setCpa(0);
    setTppd(0);
    setFinalTpPrem(0);
    setGstAmount(0);  
    onGSTChange(0);
    onTpChange(0);
    
  };
  


 

  useEffect(() => {
    calculateTP();
  }, [selectedVehicle, selectedToggle,ll, cpaValue, updateInputValues, rateData, odGst]);

  const handleInputChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
    setInputValues((prevValues) =>
      prevValues.map((input, i) => (i === index ? { ...input, value: newValue } : input))
    );

    if (inputValues[index].id === "sc") {
      setScInputValue(parseFloat(newValue));
    }
   
  }
  };

  const handleVehicleSelect = (option: Option | null) => {
    if (option) {
      const selectedObject = Data.find((obj: any) => obj.id === option.value);
      setSelectedVehicle(selectedObject || null);
        
      const gst = selectedObject?.tp?.gst ?? GST_RATES.DEFAULT;
      setGstRate(gst);
    

      // Reset input values and disabled state
      setInputValues((prevValues) =>
        prevValues.map((input) => {
          // Determine if the input should be enabled based on the new vehicle
          const isEnabled = selectedObject?.tp?.hasOwnProperty(input.id) ?? false;
  
          return { ...input, value: "", disabled: !isEnabled };
        })
      );
  
      // Clear selected toggles
      setSelectedToggle({
        CNG: false,
        ev: false,
        TPPD: false,
        NEW: false,
      });
      onTpChange(0);
      onGSTChange(0);
  
    } else {
      setSelectedVehicle(null);
      resetValues(); // Reset values when no vehicle is selected
  
      // Reset input values and disabled state
      setInputValues((prevValues) =>
        prevValues.map((input) => ({ ...input, value: "", disabled: true }))
      );
  
      // Reset toggles to false
      setSelectedToggle({
        CNG: false,
        ev: false,
        TPPD: false,
        NEW: false,
      });
      setGstRate(GST_RATES.DEFAULT);
          onGSTChange(0);
    onTpChange(0);
  
    }
  };
  

  useEffect(() => {
    updateInputValues(selectedVehicle);
  
  }, [selectedToggle, selectedVehicle,]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
  
    setSelectedToggle((prev) => {
      const updatedToggle = { ...prev, [id]: checked };
  
      // If "ev" is toggled on, set "cc" input value to 0
      if (id === "ev" && checked) {
        setInputValues((prevValues) =>
          prevValues.map((input) =>
            input.id === "cc" ? { ...input, value: "", disabled: true } : input
          )
        );
      } else if (id === "ev" && !checked) {
        // Enable "cc" input if "ev" is toggled off
        setInputValues((prevValues) =>
          prevValues.map((input) =>
            input.id === "cc" ? { ...input, value: "", disabled: false } : input
          )
        );
      }
  
      return updatedToggle;
    });

  };
  
  const handleIncrement = () => {
    const incrementValue = 50;
    const newValue = ll + incrementValue;
   
    onTpChange(incrementValue); // Notify about the increment
    setLl(ll + incrementValue); // Accumulate the increment in ll
};

const handleDecrement = () => {
    if (ll > 0) {
        const decrementValue = Math.min(50, ll); // Decrease by 50 or the remaining value in ll
        const newValue = ll - decrementValue;
      
        onTpChange(-decrementValue); // Notify about the decrement
        setLl(ll - decrementValue); // Subtract the decrement from ll
    }
};



const handleCPAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  const numericValue = Number(value); // Convert the value to a number
  if (!isNaN(numericValue)) { // Check if it's a valid number
    setCpaValue(numericValue);
  }
};
const handleOdGstChange = (gst: number) => {
  setOdGst(gst);
  console.log("odGST", gst);
 
};


const getVariant = (isSelected: boolean) => (isSelected ? "success" : "outline-danger");
  
  return (
    <div className="vehicle-and-input-organism">
      
      <Table>

      <tbody>
          <tr>
            <th rowSpan={5}>TP</th>
            <th colSpan={5}>
              <VehicleSelection options={vehicleOptions} onChange={handleVehicleSelect} />
            </th>

          </tr>

          <tr>


            {inputValues.map((input) => (
              <th key={input.id}>{input.label}</th>
            ))}
          </tr>
        

          <tr>


            {inputValues.map((input, index) => (
              <td key={input.id}>
                <input
                  type="text"
                  value={input.value}
                  onChange={handleInputChange(index)}
                  disabled={input.disabled}
                />
              </td>
            ))}
          </tr>
        
     <tr>
        <td colSpan={4}>

          <ButtonGroup className="mb-2" style= {{ zIndex: 0 }}>
            {Object.keys(selectedToggle).map((item, i) => (
             
              <ToggleButton
                key={i}
                id={item}
                type="checkbox"
                variant={getVariant(selectedToggle[item])}
                size="lg"
                value={item}
                checked={selectedToggle[item]}
                onChange={handleToggleChange}
                 className="toggle-button-uppercase"
              >
                {item}
              </ToggleButton>
            ))}
          </ButtonGroup>
          
          </td>
          <th rowSpan={2}>
            <Table size="sm">
              <thead>
                <tr><th>TP Prem. Summary</th>
                <th style={{ textAlign: 'right' }}>₹</th>
                </tr>
               
              </thead>
              <tr>
                <td>Basic TPPrem:</td>
                <td style={{ textAlign: 'right' }}>{(basicTPPrem || 0.000).toFixed(3)}</td>
              </tr>
              <tr>
                <td>SC TP in PCV:</td>
                <td style={{ textAlign: 'right' }}>{(scTPinPCV  || 0.000).toFixed(3)}</td>
              </tr>
              <tr>
                <td>LL:</td>
                <td style={{ textAlign: 'right' }}>{(ll  || 0.000).toFixed(3)}</td>
              </tr>
              <tr>
                <td>CNG:</td>
                <td style={{ textAlign: 'right' }}>{(cng  || 0.000).toFixed(3)}</td>
              </tr>
              <tr>
                <td>CPA:</td>
                <td style={{ textAlign: 'right' }}>{(cpa || 0.000).toFixed(3)}</td>
              </tr>
              <tr>
                <td >TPPD:</td>
                <td style={{ textAlign: 'right' }}>{(tppd  || 0.000).toFixed(3)}</td>
              </tr>
             <tr>
              <td>Final TP Prem:</td>
              <td style={{ textAlign: 'right' }}>{(finalTpPrem || 0.000).toFixed(3)}</td>
             </tr>
            </Table>
       </th>
          </tr>
       
          <tr>
            <th>
            Legal Liability
            </th>
            
          <td>
            <div className="d-flex">

              <Button variant="danger" onClick={handleDecrement} className="flex-shrink-1">-</Button>
              <span className="text-center p-2 w-100">{ll}</span>
              <Button variant="success" onClick={handleIncrement} className="flex-shrink-1">+</Button>
              </div>
            </td>
            
            <th>CPA</th>
            <td>
              <input
              
                type="text"
                value={cpaValue}
                onChange={handleCPAChange}
                placeholder="Enter CPA Amount"
                pattern="[0-9]*"
              />
            </td>
          </tr>


        </tbody>
        
      </Table>
      <ODComponent  selectedVehicle = {selectedVehicle} odInputValues={inputValues} onOdChange={onOdChange} onGSTChange={handleOdGstChange} />
      
    </div>
  );

});

export default TPComponent;



