// src/Pages/LandingPage.tsx
import React from "react";
import LandingTemplate from "../Template/landingTemplate";

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <LandingTemplate />
    </div>
  );
};

export default LandingPage;
