import React, { useState, useEffect, useMemo } from "react";
import ODTable from "../Molecules/oDTable";
import { ArrayType } from "../../../Types/option";
import { VehicleCategory } from "./tPComponent";
import Data from "../../../tPData.json";
import { GST_RATES } from "../../../config";

interface ODComponentProps {
  
  onOdChange: (delta: number) => void;
  onGSTChange: (rate: number) => void;
  selectedVehicle?: VehicleCategory | null;
  odInputValues: Array<{ id: string; value: string; disabled: boolean }>;
}

interface Rate {
  basic: number;
  ext: number;
  overturning: number;
  geoextension: number;
  imt23?: number;
  antiTheft: number;
  cng_int: number;
  cng_ext: number;
}

interface RateData {
  min: number;
  max: number;
  rate: Rate;
}

interface Zone {
  zone?: {
    [key: string]: any; // This can include cc, ev, and other fields dynamically
  };
}

interface Age {
  age?: {
    [key: string]: {
      [key: string]: Array<RateData>; // Adjusted to include nested rate
    };
  };
}

interface ODData {
  zone: Zone;
  age: Age;
  min: number;
  max: number;
  rate: Rate;
  gst?: number;
}

const ODComponent: React.FC<ODComponentProps> = ({
  selectedVehicle,
  odInputValues,
  
  onOdChange,
  onGSTChange,
  
}) => {
  
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({});
  const [body_idv, setBody_idv] = useState<number>(0); // State for body_idv
  const [electrol_edv, setElectrolEdv] = useState<number>(0);
  const [od_disc, setOdDisc] = useState<number>(0);
  const [zd_rate, setZdRate] = useState<number>(0);
  const [cng_input, setCngInput] = useState<number>(0);
  const [storedVehicle, setStoredVehicle] = useState<VehicleCategory | null>(null);
  const [storedInputValues, setStoredInputValues] = useState<Record<string, string>>({});
  const [ncbValue, setNcbValue] = useState<string>("");
  const [gstAmount, setGstAmount] = useState<number>(0);

  // these states are for showing od calculaion summarry
  const [basicRate, setBasicRate] = useState<number>(0);
  const [bodyOD, setbodyOd] = useState<number>(0);
  const [electrolOd, setElectrolOd] = useState<number>(0);
  const [cngPrem, setCngPrem] = useState<number>(0);
  const [geoExt, setGeoExt] = useState<number>(0);
  const [imt23, setImt23] = useState<number>(0);
  const [antiTheft, setAntiTheft] = useState<number>(0);
  const [overTurning, setOverTurning ] = useState<number>(0);
  const [totalOdPrem, setTotalOdPrem] = useState<number>(0);
  const [premAfterOdDisc, setPremAfterOdDisc] = useState<number>(0);
  const [premAfterNcb, setPremAfterNcb] = useState<number>(0);
  const [zdPrem, setZdPrem ] = useState<number>(0);
  const [finalOdPrem, setFinalOdPrem] = useState<number>(0);

  const [zoneOptions, setZoneOptions] = useState<ArrayType[]>([
    { label: "A", value: "A", disabled: true },
    { label: "B", value: "B", disabled: true },
    { label: "C", value: "C", disabled: true },
  ]);

  const ageGroups: ArrayType[] = [
    { label: "0-5", value: "low" },
    {label: "6-10", value: "mid" },
    { label: "10+", value: "high" },
  ];

  const [cngGroups, setCngGroups] = useState<ArrayType[]>([
    { label: "INT", value: "cng_int", disabled: true },
    { label: "EXT", value: "cng_ext", disabled: true },
  ]);
  

  const ncbGroups: ArrayType[] = [
    { label: "0%", value: "0" },
    { label: "20%", value: "20" },
    {label: "25%", value: "25" },
    {label: "35%", value: "35" },
    { label: "45%", value: "45" },
    { label: "50%", value: "50" },
  ];

  const [checkboxOptions, setCheckboxOptions] = useState<ArrayType[]>([
    { label: "Over Turning", value: "overturning", disabled: true },
    {label: "Geog. Ext", value: "geoextension", disabled: true },
    {label: "IMT23", value: "imt23", disabled: true },
    { label: "Anti Theft", value: "antiTheft", disabled: true },
  ]);

  const initializeSelectedValues = () => {
    const values: Record<string, string> = {};
    odInputValues.forEach(({ id, value }) => {
      values[id] = value;
    });
    return values;
  };

  useEffect(() => {
    const values = initializeSelectedValues();
    setSelectedValues(values);
    setStoredInputValues(values);
  
    if (selectedVehicle) {
      setStoredVehicle(selectedVehicle);
      updateZoneOptions(selectedVehicle);
      updateCheckboxOptions(rateData);
      onOdChange(0);
    }
  }, [odInputValues, selectedVehicle]);



  const updateZoneOptions = (storedVehicle: VehicleCategory | null) => {
    if (storedVehicle?.od) {
      console.log('Vehicle OD data:', storedVehicle.od);
      
      const updatedZoneOptions = zoneOptions.map((zone) => {
        const isEnabled = storedVehicle.od?.hasOwnProperty(zone.value) ?? false;
        return {
          ...zone,
          disabled: !isEnabled,
        };
      });
  
      setZoneOptions(updatedZoneOptions);
    } else {
      // Handle case when storedVehicle or storedVehicle.od is null
      setZoneOptions(zoneOptions.map((zone) => ({ ...zone, disabled: true })));
    }
  };
 
 
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<Record<string, boolean>>({});

  const handleToggleChange = (key: string, value: string) => {
    console.log(`Toggle Changed: ${key} - ${value}`);
    setSelectedValues((prev) => ({
      ...prev,
      [key]: value,
      ncbValue:value,
      
    }));
    if (key === 'cng') {
      setSelectedValues((prev) => ({
        ...prev,
        cng: value,
       cng_input: '', // Clear CNG input if CNG type is changed
      }));
    }
  };
 
  const handleCheckboxChange = (key: string, value: boolean) => {
  //  console.log(`Checkbox Changed: ${key} - ${value}`);

    setSelectedCheckboxValues((prev) => ({
      ...prev,
      [key]: value,
      
    }));
  };

  const handleBdyidvChange = (value: number) => setBody_idv(value);
  const handleElectrolEdv = (value: number) => setElectrolEdv(value);
  const handleOdDisc = (value: number) => setOdDisc(value);
  const handleZdRate = (value: number) => setZdRate(value);
  const handleCnginputChange = (value: number) => setCngInput(value);



const selectedZone = selectedValues['zone'];
const selectedAge = selectedValues['age'];
const inputType = Object.keys(storedInputValues).find((key) => storedInputValues[key] !== '');

const rateArray = useMemo(() => {
  if (!storedVehicle || !storedVehicle.od) return [];
  const zoneData = storedVehicle.od[selectedZone];
  const ageData = zoneData ? zoneData[selectedAge] : undefined;
  return ageData && inputType ? ageData[inputType] : [];
}, [storedVehicle, selectedZone, selectedAge, inputType]);
 
const rateData = useMemo(() => {
  if (!inputType || !Array.isArray(rateArray)) return null;
  const inputValue = parseFloat(storedInputValues[inputType] || "0");
  return rateArray.find((rate: RateData) => 
    inputValue >= rate.min && (rate.max === null || inputValue <= rate.max)
  );

}, [rateArray, storedInputValues, inputType]);
  // console.log("rate", rateData);
   const updateCheckboxOptions = (rateData: RateData | null) => {
    if (rateData) {
      const updatedCheckboxOptions = checkboxOptions.map((option) => {
        const isEnabled = rateData.rate.hasOwnProperty(option.value);
        return {
          ...option,
          disabled: !isEnabled,
        };
      });
      setCheckboxOptions(updatedCheckboxOptions);
    } else {
      setCheckboxOptions(checkboxOptions.map((option) => ({ ...option, disabled: true })));
    }
  };

  const updateCngCheckboxOptions = (rateData: RateData | null) => {
    if (rateData) {
      const updatedCngCheckboxOptions = cngGroups.map((option) => {
        const isEnabled = rateData.rate.hasOwnProperty(option.value);
        return {
          ...option,
          disabled: !isEnabled,
        };
      });
      setCngGroups(updatedCngCheckboxOptions);
    } else {
      setCngGroups(cngGroups.map((option) => ({ ...option, disabled: true })));
    }
  };
  
 

  useEffect(() => {
    if (storedVehicle) {
      
      updateCheckboxOptions(rateData);
      updateCngCheckboxOptions(rateData);
    }
  }, [storedVehicle, rateData]);

  const calculateOD = () => {
    if (!storedVehicle || !storedVehicle.od) {
      console.log("No vehicle or OD data present");
      return;
    }
      
    const { od } = storedVehicle;

    const selectedZone = selectedValues['zone'];
    const selectedAge = selectedValues['age'];
    const selectedCng = selectedValues['cng']
    const selectedNcb = selectedValues['ncbValue'];

    if (!selectedZone || !selectedAge) {
      console.log("Selected zone or age is not set");
      return;
    }

    const inputType = Object.keys(storedInputValues).find((key) => storedInputValues[key] !== '');


    const gvwInput = inputType === "gvw" ? storedInputValues["gvw"] : undefined;
    const gvwValue = parseFloat(gvwInput ?? "0");
   
    const scInput  = inputType === "sc" ? storedInputValues["sc"] : undefined;
    const scValue  = parseFloat(scInput ?? "0");

    if (!inputType) {
      console.log("No valid input type found in storedInputValues");
      return;
    }

    const zoneData = od[selectedZone];
    
    const ageData = zoneData ? zoneData[selectedAge] : undefined;
  

    if (!zoneData || !ageData) {
      console.log("No matching zone or age data found");
      return;
    }
   
    if (!rateData) {
      console.log("No rate data found for the selected type and zone/age");
      return;
    }

    const basicRate = rateData.rate.basic|| 0;

    setBasicRate(basicRate);
  //  console.log(body_idv);
  

    let currentOd  = (basicRate * body_idv)/100
    if (gvwValue > 12000) {
      currentOd +=  ((gvwValue -12000) * 27)/100;
    }
    console.log(currentOd);
    let scRate = 0;
    
    if (scValue >= 1 && scValue <= 18) {
      scRate = 350;
    } else if (scValue >= 19 && scValue <= 36) {
      scRate = 450;
    } else if (scValue >= 37 && scValue <= 60) {
      scRate = 550;
    } else if (scValue > 60) {
      scRate = 680;
    }
     currentOd = currentOd + scRate;
      
    
     setbodyOd(currentOd);
    

      let baseOd=0;
     let electrolOdPrem = (rateData.rate.ext*electrol_edv)/100;
     baseOd = currentOd+ electrolOdPrem;
      setElectrolOd(electrolOdPrem);

     let cngPremium = 0;

     if (selectedCng === 'cng_int') {
       cngPremium = (rateData.rate.cng_int * currentOd)/100;
     
     } else if (selectedCng === 'cng_ext') {
       cngPremium = (cng_input * rateData.rate.cng_ext)/100;
     }

     setCngPrem(cngPremium);
  
         baseOd = baseOd+cngPremium;
     
  let geoValue =0;
  if(selectedCheckboxValues['geoextension']){
     geoValue = rateData.rate.geoextension;
    // console.log("geoValue", geoValue);
  }
  setGeoExt(geoValue);
  const basicOd = baseOd+geoValue;
  
  let imtValue =0;
  if (selectedCheckboxValues['imt23']) {
    const imtRate = rateData.rate.imt23 || 0;
     imtValue = (imtRate * basicOd)/100;
  }
   setImt23(imtValue);

   let antiTheftValue = 0;
  if(selectedCheckboxValues['antiTheft']){
    antiTheftValue = (rateData.rate.antiTheft*baseOd)/100 ;
  }
  if(antiTheftValue>500){
    antiTheftValue = 500;
  }
    setAntiTheft(antiTheftValue);

  let overTurnValue =0;
  if(selectedCheckboxValues['overturning']){
    overTurnValue = (rateData.rate.overturning * body_idv)/100;
  }
    setOverTurning(overTurnValue);
   
   const zdPremium = (body_idv *zd_rate + electrol_edv * zd_rate + cng_input * zd_rate)/100;

   setZdPrem(zdPremium);

  baseOd = basicOd + overTurnValue +imtValue-antiTheftValue;

      setTotalOdPrem(baseOd);
        
      let premAfterOd= 0;
      if(rateData.rate.od_disc === false){
        
        premAfterOd = baseOd - (baseOd- electrolOdPrem)*od_disc/100;
      }else{
         premAfterOd = baseOd-(baseOd * od_disc)/100;
      }
      console.log("OD Discount Flag:", rateData.od_disc);


    

  setPremAfterOdDisc(premAfterOd);
  baseOd = premAfterOd;

  let ncbValue=0;
      
  if (selectedNcb) {
    const ncbPercentage = parseFloat(selectedNcb);
    if (!isNaN(ncbPercentage)) {
       ncbValue = (baseOd * ncbPercentage) / 100;
    }
  }
  setPremAfterNcb(baseOd - ncbValue);
  baseOd = baseOd-ncbValue +zdPremium;
  const totalGST  = (baseOd * GST_RATES.DEFAULT)/100;
  setFinalOdPrem(baseOd);
  
 
  setGstAmount(totalGST)
  onGSTChange(totalGST);
    onOdChange(baseOd);
  };

  useEffect(() => {
    if (selectedVehicle) {
      calculateOD();
    }
  }, [selectedVehicle, selectedCheckboxValues, selectedValues, body_idv, electrol_edv, od_disc, zd_rate, cng_input]);

 
  const resetValues = () => {
    setSelectedValues({});
    setBody_idv(0);
    setElectrolEdv(0);
    setOdDisc(0);
    setZdRate(0);
    setCngInput(0);
    setStoredVehicle(null);
    setStoredInputValues({});
    setNcbValue("");
    setGstAmount(0);
    onGSTChange(0); //it's important to do here
    setSelectedCheckboxValues({});

    // Reset summary states
    setBasicRate(0);
    setbodyOd(0);
    setElectrolOd(0);
    setCngPrem(0);
    setGeoExt(0);
    setImt23(0);
    setAntiTheft(0);
    setOverTurning(0);
    setTotalOdPrem(0);
    setPremAfterOdDisc(0);
    setPremAfterNcb(0);
    setZdPrem(0);
    setFinalOdPrem(0);

    onOdChange(0);
  };

  useEffect(() => {
    if (storedVehicle !== selectedVehicle) {
      
      resetValues(); 
    
    }
  }, [selectedVehicle]);
  
  
  return (
    <div className="zone-age-table">
      <ODTable
      basicRate={basicRate} 
      bodyOd = {bodyOD}
      cngPrem = {cngPrem}
      electrolOd = {electrolOd}
      geoExt = {geoExt}
      imt23 = {imt23}
      antiTheft = {antiTheft}
      overTurning = {overTurning}
      totalOdPrem = {totalOdPrem}
      premAfterOdDisc = {premAfterOdDisc}
      premAfterNcb = {premAfterNcb}
      zdPrem = {zdPrem}
      finalOdPrem = {finalOdPrem}
        zoneOptions={zoneOptions}
        ageGroups={ageGroups}
        cngGroups={cngGroups}
        ncbGroups={ncbGroups}
        checkboxOptions={checkboxOptions}
        selectedValues={selectedValues}
        selectedCheckboxValues={selectedCheckboxValues}
        handleToggleChange={handleToggleChange}
        handleCheckboxChange={handleCheckboxChange}
        onBdyidvChange={handleBdyidvChange} 
        onElectrolEdvChange = {handleElectrolEdv}
        onOdDiscChange={handleOdDisc}
        onZdRateChange = {handleZdRate}
        onCngInputChange = {handleCnginputChange}
           // Ensure this prop is used in ODTable
      />
      
    </div>
  );
};

export default ODComponent;



 