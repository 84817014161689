import React from "react";
import {  Table } from "react-bootstrap";
import FinancialTableRow from "../Molecules/financialTablRow";
import "./TPComponent.css";

interface FinancialSummaryOrganismProps {
  tp: number;
  gstAmount: number;
  od:number;
}




const FinancialSummaryOrganism: React.FC<FinancialSummaryOrganismProps> = ({od, tp, gstAmount }) => {
  console.log("GST Amount received:", gstAmount);
  const roundedOd = parseFloat(od.toFixed(2));
  const roundedTp = parseFloat(tp.toFixed(2));
  const roundedGst = parseFloat(gstAmount.toFixed(2));

  const net = parseFloat((roundedOd + roundedTp).toFixed(2));

  const total = parseFloat((net + roundedGst).toFixed(2));
  return (
    
        <Table className="table-container">
            <tbody>
          <th rowSpan={5}>O/P</th>
            <tr>
              <th >OD</th>
              <th>TP</th>
              <th>Net</th>
              <th>GST</th>
              <th>Total</th>
            </tr>
          
       
            <FinancialTableRow 
              od={roundedOd}
              tp={roundedTp}
              net={net}
              gst={roundedGst}
              total={total}
            />
          </tbody>
        </Table>
      
    
  );
};

export default FinancialSummaryOrganism;
