

import React, { HtmlHTMLAttributes, useState } from "react";
import { ButtonGroup,ToggleButton, Table} from "react-bootstrap";
import { ArrayType } from "../../../Types/option";
import './oDTable.css';

interface ODTableProps {
  zoneOptions: ArrayType[];
  ageGroups: ArrayType[];
  cngGroups: ArrayType[];
  ncbGroups: ArrayType[];
  checkboxOptions: ArrayType[]; 
  selectedCheckboxValues: Record<string, boolean>;
  selectedValues: Record<string, string>;
  handleToggleChange: (key: string, value: string) => void;
  handleCheckboxChange: (key: string, value: boolean) => void;
  onBdyidvChange: (value: number) => void;
  onElectrolEdvChange: (value: number) => void;
  onOdDiscChange: (value: number) =>void;
  onZdRateChange: (value:number) =>void;
  onCngInputChange: (value:number) =>void;
  basicRate: number;
  bodyOd: number;
  electrolOd:number;
 cngPrem:number;
 geoExt:number;
 imt23:number;
 antiTheft:number;
 overTurning: number;
 totalOdPrem: number;
 premAfterOdDisc: number;
 premAfterNcb: number;
 zdPrem: number;
   finalOdPrem: number;
}

const ODTable: React.FC<ODTableProps> = ({
  zoneOptions,
  ageGroups,
  cngGroups,
  ncbGroups,
  checkboxOptions,
  selectedValues,
  selectedCheckboxValues,
  handleToggleChange,
  handleCheckboxChange,
  onBdyidvChange,
  onElectrolEdvChange,
  onOdDiscChange,
  onZdRateChange,
  onCngInputChange,
  basicRate,
  bodyOd,
  electrolOd,
  cngPrem,
  geoExt,
  imt23,
  antiTheft,
  overTurning,
totalOdPrem,
 premAfterOdDisc,
 premAfterNcb,
zdPrem,
finalOdPrem,
  
}) => {
  // State to manage visibility of NCB dropdown
  const [ncbDropdownVisible, setNcbDropdownVisible] = useState<boolean>(false);
  const [bodyIdv, setBodyIdv] = useState<string>(selectedValues["body_idv"] || "");
  const [electrolEdv, setElectrolEdv] = useState<string>(selectedValues["electrol_edv"] || "");
  const [odDisc, setOdDisc] = useState<string>(selectedValues["electrol_edv"] || "");
  const [zdRate, setZdRate] = useState<string>(selectedValues["zd_rate"] || "");
  const [cngInput, setCngInput] = useState<string>(selectedValues["cng_input"] ||"");

  // Handle toggle change for NCB
  const handleNcbToggle = (value: string) => {
    setNcbDropdownVisible(value === "ncb_selected");
    handleToggleChange("ncb", (value));
  };

  const handleElectrolEdvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
    setElectrolEdv(value);
    onElectrolEdvChange(Number(value));
    }
  };

  const handleBodyIdvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
    setBodyIdv(value);
    onBdyidvChange(Number(value));
    }
  };
  const handleOdDiscChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setOdDisc(value);
      onOdDiscChange(value ? Number(value) : 0);
    }
  };
   const handleZdRateChange = (e:React.ChangeEvent<HTMLInputElement>)  =>{
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
     setZdRate(value);
     onZdRateChange(Number(value));
    }
   };
   const handleCnginput = (e:React.ChangeEvent<HTMLInputElement>) =>{
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
    setCngInput(value);
    onCngInputChange(Number(value));
    }
   };
     


  // Check if "EXT" is selected in the CNG options
  const isCngExtSelected = selectedValues.cng === "cng_ext";
  const getVariant = (isSelected: boolean) => (isSelected ? "success" : "outline-danger");

  return (

    <Table className="table">
      <tbody>
        {/* Row for Zones */}
        <tr>
          <th rowSpan={7}>OD</th>
          <th>Zone</th>
          <td colSpan={3}>
            <ButtonGroup>
              {zoneOptions.map((zone, idx) => (
                <ToggleButton
                  key={idx}
                  id={`zone-${idx}`}
                  type="radio"
                  variant={getVariant(selectedValues.zone === zone.value)}
                  size="lg"
                  name="zone"
                  value={zone.value}
                  disabled = {zone.disabled}
                  checked={selectedValues.zone === zone.value}
                  onChange={() => handleToggleChange("zone", zone.value)}
                >
                  {zone.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </td>
          <th rowSpan={7}>
          <Table striped size="sm">
          <thead>
                <tr>
                  <th>
                    OD Prem. Summary
                  </th>
                  <th style={{ textAlign: 'right' }}>₹</th>
                  </tr>
              </thead>
              <tbody>
                {[
                  { label: 'OD Rate:', value: basicRate },
                  { label: 'Body OD Prem.:', value: bodyOd },
                  { label: 'Elect. Acc. Prem.:', value: electrolOd },
                  { label: 'CNG Prem.:', value: cngPrem },
                  { label: 'Geog. Ext.:', value: geoExt },
                  { label: 'IMT23:', value: imt23 },
                  { label: 'Anti Theft:', value: antiTheft },
                  { label: 'Over Turning:', value: overTurning },
                  { label: 'Total OD Prem.:', value: totalOdPrem },
                  { label: 'Prem. after OD Disc:', value: premAfterOdDisc },
                  { label: 'Prem. after NCB:', value: premAfterNcb },
                  { label: 'ZD Prem.:', value: zdPrem },
                  { label: 'Final OD Premium:', value: finalOdPrem }
                ].map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.label}</td>
                    <td style={{ textAlign: 'right' }}>{(item.value || 0).toFixed(3)}</td>
                  </tr>
                ))}
              </tbody>
          </Table>
          </th>

        </tr>

        {/* Row for Age Groups */}
        <tr>
          <th>Age (Yrs)</th>
          <td colSpan={3}>
            <ButtonGroup>
              {ageGroups.map((age, idx) => (
                <ToggleButton
                  key={idx}
                  id={`age-${idx}`}
                  type="radio"
                  variant={getVariant(selectedValues.age === age.value)}
                  size="lg"
                  name="age"
                  value={age.value}
                  checked={selectedValues.age === age.value}
                  onChange={() => handleToggleChange("age", age.value)}
                >
                  {age.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </td>
        </tr>

        {/* Row for CNG */}
        <tr>
          <th>CNG</th>
          <td colSpan={2}>
            <ButtonGroup>
              {cngGroups.map((cng, idx) => (
                <ToggleButton
                  key={idx}
                  id={`cng-${idx}`}
                  type="radio"
                  variant={getVariant(selectedValues.cng === cng.value)}
                  size="lg"
                  name="cng"
                  value={cng.value}
                  disabled = {cng.disabled}
                  checked={selectedValues.cng === cng.value}
                  onChange={() => handleToggleChange("cng", cng.value)}
                >
                  {cng.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </td>
          
            <td>
              <input
                type="text"
                id="cng_input"
                value={cngInput}
                onChange = {handleCnginput}
                placeholder="Enter value"
                disabled={!isCngExtSelected}
              />
            </td>
        
        </tr>

        {/* Row for NCB */}
        <tr>
          <th>NCB</th>
          <td colSpan={3}>
            <ButtonGroup>
              {ncbGroups.map((ncb, idx) => (
                <ToggleButton
                  key={idx}
                  id={`ncb-${idx}`}
                  type="radio"
                  variant={getVariant(selectedValues.ncb === ncb.value)}
                  size="lg"
                  name="ncb"
                  value={ncb.value}
                  checked={selectedValues.ncb === ncb.value}
                  onChange={() => handleToggleChange("ncb",ncb.value)}
                >
                  {ncb.label}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </td>
        </tr>

        {/* Row for BODY IDV and Electrical EDV */}
        <tr>
          <th>Body IDV</th>
          <td>
            <input
              type="text"
              id="body_idv"
              value={bodyIdv}
              onChange={handleBodyIdvChange} 
              placeholder="Enter BODY IDV"
              pattern="[0-9]*" // Ensures only numeric input
            />
          </td>
          <th>Electrical Acc. IDV</th>
          <td>
            <input
              type="text"
              id="electrol_edv"
              value={electrolEdv}
              onChange={handleElectrolEdvChange} 
              placeholder="Enter Electrical EDV"
              pattern="[0-9]*" // Ensures only numeric input
            />
          </td>
        </tr>
      
        <tr>
          <th>OD Disc %</th>
          <td>
            
            <input
              type="text"
              id="od_disc"
              value={odDisc}
              onChange={handleOdDiscChange}
              placeholder="Enter OD Disc %"
              pattern="[0-9]*" // Ensures only numeric input
            />
          </td>
          <th>ZD Rate %</th>
          <td>
            <input
              type="text"
              id="zd_rate"
              value={zdRate}
              onChange = {handleZdRateChange}
              placeholder="Enter ZD Rate %"
              pattern="[0-9]*" // Ensures only numeric input
            />
            
          </td>
          </tr>
            {/* Row for Checkboxes */}
            <tr>
              <th>Other Options</th>
              <td colSpan={3}>
                <ButtonGroup>
                  {checkboxOptions.map((option, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`checkbox-${idx}`}
                      type="checkbox"
                      variant={getVariant(selectedCheckboxValues[option.value])}
                      size="lg"
                      name="checkbox"
                      disabled={option.disabled}
                      value={option.value}
                      checked={!!selectedCheckboxValues[option.value]}
                      onChange={(e) => handleCheckboxChange(option.value, !selectedCheckboxValues[option.value])}
                    >
                      {option.label}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </td>
            </tr>
      </tbody>
      
    </Table>
  );
};

export default ODTable;

