// src/Components/UI/Templates/LandingTemplate.tsx
import React, { useState } from "react";
import TPComponent from "../UI/Organisms/tPComponent";
import FinancialSummaryOrganism from "../UI/Organisms/financialSummary";
import './landingTemp.css';
import { Row, Col } from "react-bootstrap";



const LandingTemplate: React.FC = () => {
  // Initialize state for TP value
  const [tp, setTp] = useState<number>(0);
  const [od, setOD] = useState<number>(0);

  const [totalGst, setTotalGst] = useState<number>(0);

  const handleTpChange = (delta: number) => {
    setTp(delta);
  };
  
  const handleOdChange = (delta:number) =>{
    setOD(delta);
  }

  const handleTpGstChange = (gst: number) => {
    setTotalGst( gst);
    console.log("gst", gst);
  };

 
  const tpComponentRef = React.useRef<any>(null);
  return (
    <div className="landing-page-container">
      <button  className="reset-button">Reset</button>
      <div className="box">
       
         <div className="box-content">
          <Row>
            <Col>
          <FinancialSummaryOrganism tp={tp} od = {od} gstAmount={totalGst} />
          <TPComponent ref={tpComponentRef} tp={tp}  onTpChange={handleTpChange} onOdChange = {handleOdChange}
           onGSTChange={handleTpGstChange} />
        
          </Col>
          </Row>
        </div>
        </div>
        
    </div>
  );
};

export default LandingTemplate;
