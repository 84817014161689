import React from "react";

interface FinancialTableCellProps {
  value: number | string;
}

const FinancialTableCell: React.FC<FinancialTableCellProps> = ({ value }) => {
  return <td>{value}</td>;
};

export default FinancialTableCell;
