import React from "react";
import SelectInputWithLabel from "../../../../node_modules/pasenture-components/dist/UI/molecules/SelectInputWithLabel";
import { Option } from "../../../../node_modules/pasenture-components/dist/types&interface/index";

interface VehicleSelectionProps {
  options: Option[];
  onChange: (option: Option | null) => void;
}

const VehicleSelection: React.FC<VehicleSelectionProps> = ({ options, onChange }) => {
  return (
    <div className="vehicle-selection">
      
      <SelectInputWithLabel
        label=""
        options={options}
        onChange={onChange}
      />
    </div>
  );
};

export default VehicleSelection;
