import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './logo.svg';
import './App.css';
import LandingPage from './Components/Pages/landingPage';

function App() {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  );
}

export default App;
