import React from "react";
import FinancialTableCell from "../Atom/financialTableCell";
import "../Organisms/TPComponent.css";

interface FinancialTableRowProps {
  od: number;
  tp: number;
  net: number;
  gst: number;
  total: number;
}

const FinancialTableRow: React.FC<FinancialTableRowProps> = ({ od, tp, net, gst, total }) => {
  return (
    <tr>
      <FinancialTableCell value={od} />
      <FinancialTableCell value={tp} />
      <FinancialTableCell value={net.toFixed(2)} />
      <FinancialTableCell value={gst} />
      <FinancialTableCell value={total.toFixed(2)} />
    </tr>
  );
};

export default FinancialTableRow;
